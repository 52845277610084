import { Component, Injectable } from '@angular/core';
import { Http, Headers, ResponseContentType } from '@angular/http';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // title = 'www-livechek';
  // senderName = '';
  // senderMobile = '';
  // senderEmail = '';
  // emailBody = '';
  // test: Date = new Date();

  constructor(private http: Http) {}
  // sendEmail() {
  //   if (this.senderName.length < 3) {
  //     alert('Enter valid name');
  //   } else if (this.emailBody.length < 10) {
  //     alert('Enter valid Message');
  //   } else {
  //     var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //     if (!emailRegEx.test(this.senderEmail.trim())) {
  //       alert('Enter valid Email');
  //     } else {
  //       this.emailBody = this.emailBody.replace(/\n/g, '<br>');
  //       let data = {
  //         name: this.senderName,
  //         email: this.senderEmail,
  //         mobile: this.senderMobile,
  //         message: this.emailBody,
  //       };
  //       this.api(data)
  //         .then((res) => {
  //           this.senderName = '';
  //           this.senderEmail = '';
  //           this.senderMobile = '';
  //           this.emailBody = '';
  //           alert('Message sent Successfully');
  //         })
  //         .catch((err) => {
  //           this.senderName = '';
  //           this.senderEmail = '';
  //           this.senderMobile = '';
  //           this.emailBody = '';
  //           alert('Send Message Error! Please try again!');
  //         });
  //     }
  //   }
  // }
  // api(data) {
  //   return new Promise((resolve, reject) => {
  //     let method = this.http.post(
  //       'https://newapi.livechek.com/api/query?type=web',
  //       data
  //     );
  //     return method.subscribe(
  //       (res) => {
  //         resolve(res.json());
  //       },
  //       (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }
}
